/* Header container */
header {
  display: flex;
  justify-content: space-between; /* Space between logo/title and navigation */
  align-items: center; /* Vertically center content */
  padding: 0 2rem; /* Consistent horizontal padding */
  height: var(--header-height); /* Use the global height variable */
  /* background-color: #6b6b6b; Background color */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); Optional shadow for depth */
  position: fixed; /* Keeps header fixed at the top */
  top: 0; /* Ensures it's at the top of the viewport */
  width: 97vw; /* Full width of the viewport */
  z-index: 10; /* Ensure it stays above other elements */
}

/* Header title or logo */
header h1 {
  margin: 0;
  font-size: 1.5rem; /* Adjust to fit the reduced height */
  font-family: "Poppins", sans-serif; /* Clean font */
  color: #3e0fac; /* Text color */
}

/* Navigation container */
header nav {
  display: flex;
  gap: 1.5rem; /* Space between navigation links */
}

/* Navigation links */
header nav a {
  text-decoration: none; /* Remove underline */
  color: #ffffff; /* Link color */
  font-size: 1rem; /* Adjust size for readability */
  font-weight: 500; /* Semi-bold for emphasis */
  font-family: "Poppins", sans-serif;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Hover effect for links */
header nav a:hover {
  text-decoration: underline; /* Underline on hover */
  transform: scale(1.1); /* Slight scale effect */
  color: #ddd; /* Lighter color on hover */
}
