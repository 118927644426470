/* Footer container */
footer {
  display: flex;
  justify-content: space-between; /* Space between text and navigation */
  align-items: center; /* Center items vertically */
  padding: 1rem 2rem; /* Spacing around the footer */
  background-color: #6b6b6b; /* Footer background color */
  border-top: 2px solid #444; /* Border to separate footer */
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  position: relative; /* Keeps footer in the flow of the page */
  z-index: 5;
}

/* Footer text */
footer p {
  margin: 0; /* Remove default margin */
  font-size: 1rem; /* Adjust for readability */
  color: #ffffff; /* Text color */
  flex: 1; /* Push text to the left */
  font-family: "Poppins", sans-serif; /* Consistent font */
}

/* Footer navigation */
footer nav {
  display: flex;
  gap: 1.5rem; /* Space between navigation links */
  flex: 1; /* Allow navigation to adjust layout */
  justify-content: flex-end; /* Align links to the right */
}

/* Footer links */
footer nav a {
  text-decoration: none; /* Remove default underline */
  color: #ffffff; /* Link color */
  font-size: 1rem; /* Adjust for readability */
  font-weight: 500; /* Semi-bold for emphasis */
  font-family: "Poppins", sans-serif; /* Consistent font */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

/* Hover effect for links */
footer nav a:hover {
  text-decoration: underline; /* Underline on hover */
  transform: scale(1.1); /* Slight scale effect */
  color: #ddd; /* Lighter color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  footer {
    flex-direction: column; /* Stack content vertically */
    align-items: flex-start; /* Align items to the left */
    padding: 1rem; /* Reduce padding */
  }

  footer p {
    margin-bottom: 1rem; /* Add space between text and nav */
    text-align: center; /* Center-align text for smaller screens */
    flex: unset; /* Remove flex behavior */
  }

  footer nav {
    justify-content: center; /* Center navigation links */
    gap: 1rem; /* Reduce gap */
    flex: unset; /* Remove flex behavior */
  }

  footer nav a {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}
