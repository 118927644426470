/* #section-home container */
#section-home {
    position: fixed;
    padding-left: -10px; /* This is invalid, remove it or adjust to 0 */
    display: flex;
    margin: 0; /* Remove any margin to prevent scrolling issues */
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; /* Center contents vertically */
    align-items: center; /* Center the container horizontally */
    gap: 1rem; /* Space between elements */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: relative; /* Required for pseudo-element positioning */
    overflow: hidden; /* Prevent scrolling caused by pseudo-element or content overflow */
    z-index: 0;
}

/* Blurred background using pseudo-element */
#section-home::before {
    margin-top: -6px;
    content: ""; /* Required for the pseudo-element */
    position: fixed; /* Place it behind the #section-home's content */
    width: 100vw; /* Ensure it covers the full width of the viewport */
    height: 100vh; /* Ensure it covers the full height of the viewport */
    background-image: url('../../public/assests/images/cool-background.svg');
    background-size: cover; /* Ensures the image covers the entire #section-home */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling */
    filter: blur(2px); /* Apply blur effect */
    background-color: rgba(0, 0, 0, 0); /* Add dark overlay to reduce exposure */
    background-blend-mode: darken; /* Combine the overlay with the image */
    overflow: hidden;
    z-index: -1; /* Place it behind the #section-home's content */
}

#section-home div {
    position: fixed;
    margin: 210px;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

/* Heading styles */
#section-home h1 {
    font-size: 5rem;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
    margin: 0; /* Remove default margin */
    color: #3e0fac; /* Dark text color */
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
    align-self: flex-start; /* Ensure the text itself is left-aligned */
    z-index: 0;
}

/* Subheading styles */
#section-home h2 {
    font-size: 6rem;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
    margin: 0;
    color: #ffffff;
    text-shadow: 3px 3px 16px rgba(0, 0, 0, 0.7); /* Add more pronounced shadow */
    align-self: flex-start; /* Align to the left within the #section-home */
}

/* Paragraph styles */
#section-home p {
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem; /* Adjust for readability */
    font-weight: 1000;
    margin: 0;
    color: #0c3366; /* Subtle gray text color */
    text-shadow: 2px 2px 16px rgba(0, 0, 0, 0.5); /* Consistent shadow with h1 */
    align-self: flex-start; /* Align to the left within the #section-home */
}
